const firebaseConfig = {
  projectId: "west-rock-vwice",
  appId: "1:821840667795:web:2bf2a1d4f9965cb0a4d966",
  databaseURL:
    "https://west-rock-vwice-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "west-rock-vwice.firebasestorage.app",
  apiKey: "AIzaSyAwa7hPsWr5YTXA_xphOwH_fqAT0lK3Z_Q",
  authDomain: "west-rock-vwice.firebaseapp.com",
  messagingSenderId: "821840667795",
};

export default firebaseConfig;
